const routes = {
  UpdateCertificate:'/customers/certificates/update',
  CreateCertificate:'/customers/certificates/add',
  getCandidateLists:'/customers/candidates',
  getCertificates:'/customers/certificates',
  ServiceInvoiceDetail:'/customers/services/details',
  UpdateAddOnservice:'/customers/services/update',
  getInvoices:'/customers/services',
  CreateAddOnService:'/customers/services/add',
  requestBuyerId: 'customers/requestBuyerId',
  requestBuyerIdAgain: 'customers/requestBuyerIdAgain',
  getCustomerDropDown: 'customers/dropdown',
  getCustomerQueue: '/customers',
  getCustomerDetail: 'customers/details',
  getBuyerIdDetail: 'customers/buyerId/details',
  getBuyerIdByAuctionId: 'customers/request/details',
  verifyFinance: 'customers/verifyFinance',
  allocateBuyerId: 'customers/allocateBuyerId',
  deallocateBuyerId: 'customers/deallocateBuyerId',
  changeAllocationStatus: 'customers/changeAllocationStatus',
  getMessages: 'customers/messages',
  sendMessage: 'customers/sendMessage',
  getCustomerBooking: 'customers/booking',
  getCustomerBuyerId: 'customers/buyerIds',
  deleteId:'customers/removeAuctionHouse',
  getGalaxyCustomerList:'customers/galaxy',
  CreateMemo:'customers/sendMemo',
  CreateNotifcation:'customers/sendNotifications',
  ChangeBranch:'customers/updateBranch',
  ChangeType:'customers/updateTrustedStatus',
  CreateSubCustomer:'users/addSubCustomer',
  getSubCustomerList:'users/subCustomers',
  SelectCustomer:'vehicleBookings/update',
  getAgents:'/agents',
  addCustomer:'/customers/add',
  UpdateCustomer:'customers/update',
  AddVisa:'/visas/addRequest',
  VisaProcessing:'/visas/processing',
  getWPSList:'/customers/wps',
  UpdateWPStatus:'/customers/wps/update',
  CreateWPS:'/customers/wps/add',
  UpdateAddOnService:'/customers/services/update',
  getCandidateDetail:'/customers/candidates/details',
  CustomerStatus:'customers/updateStatus'
};

export default routes