import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  IconButton,
  CircularProgress,
  Chip,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Tooltip,
  Checkbox,
  InputAdornment,
  TextField,
  Button,
  Radio,
} from "@mui/material";
import {
  AllocateIcon,
  CheckIcon,
  EyeIcon,
  FontFamily,
  Images,
  MessageIcon,
  PendingIcon,
  RequestBuyerIdIcon,
} from "assets";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import Colors from "assets/Style/Colors";
import { CircleLoading } from "components/Loaders";
import { ErrorToaster, SuccessToaster } from "components/Toaster";
import FinanceStatusDialog from "components/Dialog/FinanceStatusDialog";
import AllocateStatusDialog from "components/Dialog/AllocateStatusDialog";
import AllocateDialog from "components/Dialog/AllocateDialog";
import CustomerServices from "services/Customer";
import { makeStyles } from "@mui/styles";
import Pagination from "components/Pagination";
import {
  Debounce,
  encryptData,
  formatPermissionData,
  handleExportWithComponent,
} from "utils";
import InputField from "components/Input";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addPermission } from "redux/slices/navigationDataSlice";
import SimpleDialog from "components/Dialog/SimpleDialog";
import { PrimaryButton } from "components/Buttons";
import SelectField from "components/Select";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { PDFExport } from "@progress/kendo-react-pdf";
import moment from "moment";
import CommissionServices from "services/Commission";
import LabelCustomInput from "components/Input/LabelCustomInput";
import { showErrorToast, showPromiseToast } from "components/NewToaster";
import { adjustSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import VisaServices from "services/Visa";
import DatePicker from "components/DatePicker";
import SystemServices from "services/System";
import UploadFile from "components/UploadFile";
import { CleanTypes, getFileSize } from "utils";
import instance from "config/axios";
import routes from "services/System/routes";
import UploadFileSingle from "components/UploadFileSingle";
import { useAuth } from "context/UseContext";

// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontFamily: "Public Sans",
    border: "1px solid #EEEEEE",
    padding: "15px",
    textAlign: "left",
    whiteSpace: "nowrap",
    color: "#434343",
    paddingRight: "50px",
    background: "transparent",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Public Sans",

    textWrap: "nowrap",
    padding: "12px !important",
    paddingLeft: "15px !important",

    ".MuiBox-root": {
      display: "flex",
      gap: "6px",
      alignItems: "center",
      justifyContent: "center",
      ".MuiBox-root": {
        cursor: "pointer",
      },
    },
    svg: {
      width: "auto",
      height: "24px",
    },
    ".MuiTypography-root": {
      textTransform: "capitalize",
      fontFamily: FontFamily.NunitoRegular,
      textWrap: "nowrap",
    },
    ".MuiButtonBase-root": {
      padding: "8px",
      width: "28px",
      height: "28px",
    },
  },
}));

const useStyles = makeStyles({
  loaderWrap: {
    display: "flex",
    height: 100,
    "& svg": {
      width: "40px !important",
      height: "40px !important",
    },
  },
});

function VisaList() {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    getValues: getValues2,
    formState: { errors: errors2 },
  } = useForm();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const [statusDialog, setStatusDialog] = useState(false);
  const [activaVisaDialog, setActiveVisaDialog] = useState(false);
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [dateDialog, setDateDialog] = useState(null)

  const tableHead = [
    ...(isClicked ? [{ name: "Select", key: "" }] : []),
    { name: "VR No.", key: "" },
    { name: "Date", key: "created_at" },
    { name: "Customer", key: "created_at" },
    { name: "Passport Number", key: "candidate_name" },
    { name: "Candidate Name", key: "candidate_name" },
    { name: "Visa Rate", key: "visa_rate" },
    { name: "Visa Expiry", key: "visa_expiry" },
    { name: "Visa Type", key: "visa_type" },
    { name: "Status", key: "" },

    { name: "Change Status/Entry Permit Date", key: "" },
    { name: "Action", key: "" },
  ];
  //   state for visaprocessing use it later saraib
  const [candidates, setCandidates] = useState([]);

  const allowFilesType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "application/pdf",
    "application/vnd.ms-excel",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const [loader, setLoader] = useState(false);

  const [sort, setSort] = useState("asc");

  // *For Customer Queue
  const [customerQueue, setCustomerQueue] = useState([
    { id: 1, name: "asdasd" },
    { id: 1, name: "asdasd" },
  ]);

  // *For setPermissions
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedVisa, setSelectedVisa] = useState(null);
  const { user, userLogout } = useAuth();
  const [itemAmount, setItemAmount] = useState();
  const [status, setStatus] = useState();
  const [date, setDate] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [buttonValue, setButtonValue] = useState("");
  const [uploadedSize, setUploadedSize] = useState(0);
  const [progress, setProgress] = useState(0);
  const [slipDetail, setSlipDetail] = useState([]);
  const [document, setDocument] = useState(null);
  const [cost, setCost] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [disabled, setDisabled] = useState(false)

  // *For Filters
  const [filters, setFilters] = useState({});

  // *For Permissions
  const [permissions, setPermissions] = useState();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleRadioChange = (item) => {
    console.log(item);
    setSelectedItem(item);
  };

  // *For Get Customer Queue

  const UpdateStatus = async () => {
    setDisabled(true)
    setStatusDialog(false);
    try {
      let obj = {
        id: selectedVisa?.id,
        candidate_name: selectedVisa?.name,
        visa_id: selectedVisa?.visa_id,
        visa_charges: selectedVisa?.visa_charges,
        status: status?.id,
        date: moment(date).format("YYYY-MM-DD"),
        visa_tenure: selectedVisa?.visa_tenure,
        document: document,
      };

      const promise = VisaServices.CandidateUpdateStatus(obj);
      console.log(promise);

      showPromiseToast(
        promise,
        "Saving...",
        "Added Successfully",
        "Something Went Wrong"
      );

      // Await the promise and then check its response
      const response = await promise;
      if (response?.responseCode === 200) {
        setDisabled(false)
        getVisaRequestList();
        setStatus(null)
      }
    } catch (error) {
      console.log(error);
    }
  };



  const UpdateDate = async () => {
    setDisabled(true)
    setStatusDialog(false);
    try {
      let obj = {
        id: selectedVisa?.id,
        
        change_status_date: moment(date).format("YYYY-MM-DD"),
    
      };

      const promise = VisaServices.UpdateDate(obj);
      console.log(promise);

      showPromiseToast(
        promise,
        "Saving...",
        "Added Successfully",
        "Something Went Wrong"
      );

      // Await the promise and then check its response
      const response = await promise;
      if (response?.responseCode === 200) {
        setDisabled(false)
        getVisaRequestList();
        setDate(null)
        setStatus(null)
        setDateDialog(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateActiveVisa = async () => {
    try {
      let obj = {
        type: buttonValue,
        candidate_id: selectedItem?.id,
        visa_id: selectedItem?.visa_id,
        document: document,
        date: new Date(),
      };
      console.log(obj);

      const promise = VisaServices.visaProcessingAdd(obj);

      showPromiseToast(
        promise,
        "Saving...",
        "Added Successfully",
        "Something Went Wrong"
      );

      // Await the promise and then check its response
      const response = await promise;
      if (response?.responseCode === 200) {
        setActiveVisaDialog(false);
        getVisaRequestList();
        setDocument("");
        setButtonValue("");
        setIsClicked(false);
        setSelectedItem(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDate = (newDate) => {
    try {
      // eslint-disable-next-line eqeqeq
      if (newDate == "Invalid Date") {
        setDate("invalid");
        return;
      }
      setDate(new Date(newDate));
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Handle Filter
  const handleFilter = () => {
    let data = {
      search: getValues("search"),
    };
    Debounce(() => getVisaRequestList(1, "", data));
  };

  const getVisaRequestList = async (page, limit, filter) => {
    // setLoader(true)
    try {
      const Page = page ? page : currentPage;
      const Limit = limit ? limit : pageLimit;
      const Filter = { ...filters, ...filter };
      setCurrentPage(Page);
      setPageLimit(Limit);
      setFilters(Filter);
      let params = {
        page: Page,
        limit: Limit,
        approval_status: "Approved",
        customer_id: user?.user_type == "C" ? user?.customer_id : null,
      };
      params = { ...params, ...Filter };

      const { data } = await CustomerServices.getCandidateLists(params);
      setCandidates(data?.rows);

      setTotalCount(data?.count);
      console.log(formatPermissionData(data?.permissions), 'perm');
      setPermissions(formatPermissionData(data?.permissions));
      data?.permissions.forEach((e) => {
        if (e?.route && e?.identifier && e?.permitted) {
          dispatch(addPermission(e?.route));
        }
      });
    } catch (error) {
      showErrorToast(error);
    } finally {
      // setLoader(false)
    }
  };

  const handleSort = (key) => {
    let data = {
      sort_by: key,
      sort_order: sort,
    };
    // Debounce(() => getVisaRequestList(1, '', data));
  };
  const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    "& .MuiSvgIcon-root": {
      display: "none", // Hide the default check icon
    },
    "&.Mui-checked": {
      // This class applies when the checkbox is checked
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        top: "3px", // Adjust this value to control top padding
        left: "3px", // Adjust this value to control left padding
        width: "calc(100% - 6px)", // Adjust this value to control width
        height: "calc(100% - 6px)", // Adjust this value to control height
        backgroundColor: Colors.primary, // Background color when checked
        borderRadius: "4px", // Ensure border-radius matches checkbox
      },
    },

    width: 20, // Set the width of the checkbox
    height: 20, // Set the height of the checkbox
    borderRadius: 4, // Border-radius for the checkbox
    border: "1px solid grey", // Border for the checkbox
    position: "relative", // Ensure that the pseudo-element positions correctly
  }));
  const handleClick = (value) => {
    setIsClicked(true);
    // Handle the value as needed, for example:
    console.log(value);
    // Or set a state or call another function with the value
    setButtonValue(value);
  };

  const handleUploadDocument = async (e) => {
    try {
      e.preventDefault();
      const file = e.target.files[0];
      let arr = [
        {
          name: file?.name,
          file: "",
          type: file?.type.split("/")[1],
          size: getFileSize(file.size),
          isUpload: false,
        },
      ];
      if (allowFilesType.includes(file.type)) {
        let maxSize = 10 * 1024 * 1024;
        if (file.size > maxSize) {
          showErrorToast("File Size Must Be Less than 10 MB");
        } else {
          const path = await handleUpload(file, arr);
          console.log("Uploaded file path:", path);
          console.log(path, "pathpathpath");
          return path;
        }
      } else {
        ErrorToaster(`Only ${CleanTypes(allowFilesType)} formats is supported`);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleUpload = async (file, docs) => {
    setProgress(0);
    try {
      const formData = new FormData();
      formData.append("document", file);
      console.log(file);
      const { data } = await instance.post(routes.uploadDocuments, formData, {
        onUploadProgress: (progressEvent) => {
          const uploadedBytes = progressEvent.loaded;
          const percentCompleted = Math.round(
            (uploadedBytes * 100) / progressEvent.total
          );

          setProgress(percentCompleted);
          console.log(getFileSize(uploadedBytes));
          setUploadedSize(getFileSize(uploadedBytes));
        },
      });
      if (data) {
        docs[0].isUpload = true;
        docs[0].file = data?.data?.nations;
        setSlipDetail(docs);
        console.log(data, "asddasasd");
        return data?.data?.path;
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };
  useEffect(() => {
    getVisaRequestList();
  }, []);
  const handleOpenStatusDialog = (item) => {
    if (permissions.processing_status_update) {

      if (user?.user_type != "C") {

        setSelectedVisa(item); // Set the currently selected visa
        const sortedData = item?.statuses.sort((a, b) => a.id - b.id); // Sort statuses
        // setStatuses(getAvailableStatuses(sortedData));
        // setStatus(sortedData[sortedData.length - 1]?.status); // Set the current status
        setStatusDialog(true); // Open the dialog
      }
    }
  };
  // const getAvailableStatuses = (sortedData) => {
  //   const lastStatus = sortedData[sortedData.length - 1]?.status;

  //   switch (lastStatus) {
  //     case "In Progress":
  //       return [

  //         { id: "Change Status", name: "Change Status" },
  //         { id: "Reject", name: "Reject" },
  //       ];

  //     case "Pending":
  //       return [
  //         { id: "In Progress", name: "In Progress" },
  //         { id: "Reject", name: "Reject" },
  //       ];

  //     case "Entry Permit":
  //       return [

  //         { id: "Medical", name: "Medical" },
  //         { id: "Reject", name: "Reject" },
  //       ];

  //     case "Change Status":
  //       return [

  //         { id: "Medical", name: "Medical" },
  //         { id: "Reject", name: "Reject" },
  //       ];

  //     case "Medical":
  //       return [

  //         { id: "Emirates Id", name: "Emirates Id" },
  //         { id: "Reject", name: "Reject" },
  //       ];

  //     case "Emirates Id":
  //       return [

  //         { id: "Stamping", name: "Stamping" },
  //         { id: "Reject", name: "Reject" },
  //       ];

  //     case "Stamping":
  //       return [

  //         { id: "Reject", name: "Reject" },
  //       ];

  //     default:
  //       return [];
  //   }
  // };
  return (
    <Box sx={{ p: 3 }}>
      <SimpleDialog
        open={statusDialog}
        onClose={() => setStatusDialog(false)}
        title={"Change Status?"}
      >
        <Box component="form" onSubmit={handleSubmit(UpdateStatus)}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <SelectField
                size={"small"}
                label={"Select Status :"}
                options={statuses}
                selected={
                  status // Initially select the first candidate's latest status
                }
                onSelect={(value) => {
                  setStatus(value);
                }}
                error={errors?.status?.message}
                register={register("status", {
                  required: "Please select status.",
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DatePicker
                label={"Date :"}
                value={date}
                size={"small"}
                error={errors?.date?.message}
                register={register("date", {
                  required: "Please enter  date.",
                })}
                onChange={(date) => {
                  handleDate(date);
                  setValue("date", date);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ fontWeight: "bold" }}>Upload Document:</Box>
              <UploadFileSingle
                Memo={true}
                accept={allowFilesType}
                error={errors?.document?.message}
                file={document}
                register={register("document", {
                  required: false,
                  onChange: async (e) => {
                    const path = await handleUploadDocument(e);
                    if (path) {
                      setDocument(path);
                    }
                  },
                })}
              />
            </Grid>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid
                item
                xs={6}
                sm={6}
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "25px",
                }}
              >
                <PrimaryButton
                  disabled={disabled}
                  bgcolor={Colors.primary}
                  title="Yes,Confirm"
                  type="submit"
                />
                <PrimaryButton
                  onClick={() => setStatusDialog(false)}
                  bgcolor={"#FF1F25"}
                  title="No,Cancel"
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </SimpleDialog>
      <SimpleDialog
        open={dateDialog}
        onClose={() => setDateDialog(false)}
        title={"Change Date?"}
      >
        <Box component="form" onSubmit={handleSubmit2(UpdateDate)}>
          <Grid container>
          
            <Grid item xs={12} sm={12}>
              <DatePicker
                label={"Date :"}
                value={date}
                size={"small"}
                error={errors?.date?.message}
                register={register2("date", {
                  required: "Please enter  date.",
                })}
                onChange={(date) => {
                  handleDate(date);
                  setValue2("date", date);
                }}
              />
            </Grid>
           
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid
                item
                xs={6}
                sm={6}
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "25px",
                }}
              >
                <PrimaryButton
                  disabled={disabled}
                  bgcolor={Colors.primary}
                  title="Yes,Confirm"
                  type="submit"
                />
                <PrimaryButton
                  onClick={() => setDateDialog(false)}
                  bgcolor={"#FF1F25"}
                  title="No,Cancel"
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </SimpleDialog>
      {/* <SimpleDialog
        open={statusDialog}
        onClose={() => setStatusDialog(false)}
        title={"Change Status?"}
      >
        <Box component="form" onSubmit={handleSubmit(UpdateStatus)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <SelectField
                size={"small"}
                label={"Select Status :"}
                options={[
                  { id: false, name: "rejected" },
                  { id: true, name: "approved" },
                ]}
                selected={status}
                onSelect={(value) => {
                  setStatus(value);
                }}
                error={errors?.status?.message}
                register={register("status", {
                  required: "Please select status.",
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DatePicker
                label={"Date :"}
                value={date}
                size={"small"}
                error={errors2?.date?.message}
                register={register2("date", {
                  required: "Please enter  date.",
                })}
                onChange={(date) => {
                  handleDate(date);
                  setValue2("date", date);
                }}
              />
            </Grid>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid
                item
                xs={6}
                sm={6}
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "25px",
                }}
              >
                <PrimaryButton
                  bgcolor={Colors.primary}
                  title="Yes,Confirm"
                  type="submit"
                />
                <PrimaryButton
                  onClick={() => setStatusDialog(false)}
                  bgcolor={"#FF1F25"}
                  title="No,Cancel"
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </SimpleDialog> */}
      <SimpleDialog
        open={activaVisaDialog}
        onClose={() => {
          setActiveVisaDialog(false);
          setDocument("");
        }}
        title={buttonValue + " " + "Visa"}
      >
        <Box component="form" onSubmit={handleSubmit(updateActiveVisa)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box sx={{ fontWeight: "bold" }}>Upload Document:*</Box>
              <UploadFileSingle
                Memo={true}
                accept={allowFilesType}
                error={errors?.document?.message}
                file={document}
                register={register("document", {
                  required: false,
                  onChange: async (e) => {
                    const path = await handleUploadDocument(e);
                    if (path) {
                      setDocument(path);
                    }
                  },
                })}
              />
            </Grid>

            <Grid container sx={{ justifyContent: "center" }}>
              <Grid
                item
                xs={6}
                sm={6}
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "25px",
                }}
              >
                <PrimaryButton
                  bgcolor={Colors.primary}
                  title="Yes,Confirm"
                  type="submit"
                />
                <PrimaryButton
                  onClick={() => {
                    setActiveVisaDialog(false);
                    setDocument("");
                  }}
                  bgcolor={"#FF1F25"}
                  title="No,Cancel"
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </SimpleDialog>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Visa Processing Management List
        </Typography>
        {/* <Box sx={{ display: "flex", gap: "10px" }}>
          {isClicked == true ? (
            <>
              {selectedItem && (
                <Button
                  sx={{
                    border: `2px solid ${Colors.primary}`,
                    padding: "10px 20px",
                    borderRadius: "10px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: Colors.primary,
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => setIsClicked(false)}
                >
                  Cancel
                </Button>
              )}
              {selectedItem && (
                <Button
                  sx={{
                    padding: "10px 20px",
                    borderRadius: "10px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: Colors.white,
                    display: "flex",
                    background: Colors.primary,
                    alignItems: "center",
                    "&:hover": {
                      background: Colors.primary,
                    },
                  }}
                  onClick={() => setActiveVisaDialog(true)}
                >
                  Next
                </Button>
              )}
            </>
          ) : (
            <>
              {permissions?.renew && (
                <Button
                  sx={{
                    border: `2px solid ${Colors.green}`,
                    padding: "10px 20px",
                    borderRadius: "10px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: Colors.green,
                    backgroundColor: Colors.lightGreen,
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => handleClick("Renew")}
                >
                  Renew
                </Button>
              )}

              {permissions?.cancel && (
                <Button
                  sx={{
                    border: "2px solid rgba(229, 37, 42, 1)",
                    padding: "10px 20px",
                    borderRadius: "10px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "rgba(229, 37, 42, 1)",
                    backgroundColor: "rgba(229, 37, 42, 0.1)",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => handleClick("Cancel")}
                >
                  Cancel
                </Button>
              )}

              {permissions?.absconder && (
                <Button
                  sx={{
                    border: `2px solid ${Colors.orange}`,
                    padding: "10px 20px",
                    borderRadius: "10px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: Colors.orange,
                    backgroundColor: Colors.lightOrange,
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => handleClick("Absconder")}
                >
                  Absconder
                </Button>
              )}
            </>
          )}
        </Box> */}
      </Box>

      {/* Filters */}
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LabelCustomInput
              type={"text"}
              bgcolor={"#FAFAFA"}
              color={Colors.primary}
              border={"3px solid #FAFAFA"}
              StartLabel={"Search"}
              placeholder={"Search"}
              register={register("search")}
            />
          </Grid>

          <Grid
            item
            xs={6}
            display={"flex"}
            justifyContent={"flex-end"}
            gap={2}
          >
            <PrimaryButton
              bgcolor={Colors.white}
              textcolor={Colors.primary}
              // border={`1px solid ${Colors.primary}`}
              title="Clear"
              // onClick={() => { getVisaRequestList(); setValue('search', '') }}
              loading={loading}
            />
            <PrimaryButton
              bgcolor={Colors.buttonBg}
              title="Search"
              onClick={() => handleFilter()}
              loading={loading}
            />
          </Grid>
        </Grid>

        <Grid item md={11}>
          {candidates && (
            <Box>
              <Grid container mb={2}></Grid>

              {candidates && (
                <Fragment>
                  <PDFExport
                    ref={contentRef}
                    landscape={true}
                    paperSize="A4"
                    margin={5}
                    fileName="Import Customers"
                  >
                    <TableContainer
                      component={Paper}
                      sx={{
                        maxHeight: "calc(100vh - 200px)",
                        mt: 5,
                        backgroundColor: "transparent",
                        boxShadow: "none !important",
                        borderRadius: "0px !important",
                      }}
                    >
                      <Table stickyHeader sx={{ minWidth: 500 }}>
                        <TableHead>
                          <Row>
                            {tableHead.map((cell, index) => (
                              <Cell
                                style={{
                                  textAlign:
                                    cell?.name == "SR No." ? "center" : "left",
                                  paddingRight:
                                    cell?.name == "SR No." ? "15px" : "50px",
                                }}
                                className="pdf-table"
                                key={index}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  {cell?.name}{" "}
                                  {cell?.name == "Date" && (
                                    <>
                                      &nbsp;
                                      <span
                                        style={{
                                          height: "20px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Box
                                          component={"img"}
                                          onClick={() => {
                                            setSort(
                                              sort == "asc" ? "desc" : "asc"
                                            );
                                            handleSort(cell?.key);
                                          }}
                                          src={Images.sortIcon}
                                          width={"18px"}
                                        ></Box>
                                      </span>
                                    </>
                                  )}
                                </Box>
                              </Cell>
                            ))}
                          </Row>
                        </TableHead>
                        <TableBody>
                          {candidates?.map((item, index) => {
                            let sorteddata = item?.statuses.sort(
                              (a, b) => a.id - b.id
                            );
                            console.log(sorteddata);
                            return (
                              <Row
                                key={index}
                                sx={{
                                  border: "1px solid #EEEEEE !important",
                                }}
                              >
                                {/* {isClicked && (
                                  <Cell
                                    sx={{
                                      textAlign: "center",
                                      span: {
                                        padding: "8px",
                                        width: "22px !important",
                                        height: "23px !important",
                                      },
                                    }}
                                    className="pdf-table"
                                  >
                                    <input
                                      type="radio"
                                      checked={selectedItem?.id === item?.id}
                                      onChange={() => handleRadioChange(item)}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Cell>
                                )} */}
                                <Cell
                                  style={{ textAlign: "center" }}
                                  className="pdf-table"
                                >
                                  {item?.visa_id + "_" + item?.serial_id}
                                </Cell>
                                <Cell
                                  style={{ textAlign: "left" }}
                                  className="pdf-table"
                                >
                                  {moment(item?.created_at).format(
                                    "MM-DD-YYYY"
                                  )}
                                </Cell>
                                <Cell
                                  style={{ textAlign: "left" }}
                                  className="pdf-table"
                                >
                                  {item?.visa_request?.customer?.name}
                                </Cell>
                                <Cell
                                  style={{ textAlign: "left" }}
                                  className="pdf-table"
                                >
                                  {item?.passport_number}
                                </Cell>
                                <Cell
                                  style={{ textAlign: "left" }}
                                  className="pdf-table"
                                >
                                  {item?.name}
                                </Cell>
                                <Cell
                                  style={{ textAlign: "left" }}
                                  className="pdf-table"
                                >
                                  {item?.visa_charges
                                    ? item?.visa_charges
                                    : "0"}
                                </Cell>
                                <Cell
                                  style={{ textAlign: "left" }}
                                  className="pdf-table"
                                >
                                  {item?.visa_expiry
                                    ? moment(item?.visa_expiry).format(
                                      "MM-DD-YYYY"
                                    )
                                    : "-"}
                                </Cell>
                                <Cell
                                  style={{ textAlign: "left" }}
                                  className="pdf-table"
                                >
                                  {item?.visa_type}
                                </Cell>

                                <Cell
                                  style={{ textAlign: "left" }}
                                  className="pdf-table"
                                  onClick={() => handleOpenStatusDialog(item)}
                                >
                                  <Box
                                    component={"div"}
                                    sx={{
                                      cursor: "pointer",
                                      display: "flex !important",
                                      justifyContent: "flex-start !important",
                                    }}
                                    onClick={() => {
                                      // if (permissions?.update_candidate_status) {
                                      // if (permissions?.update_candidate_status) {

                                      // setStatusDialog(true);
                                      // }
                                      // }
                                      setSelectedVisa(item);
                                      setStatus(null)
                                      setDate(null)
                                      setDocument('')
                                      if (
                                        sorteddata[sorteddata?.length - 1]
                                          ?.status == "In Progress"
                                      ) {
                                        if (item?.visa_type.toLowerCase() == "out") {
                                          setStatuses([
                                            // {
                                            //   id: "In Progress",
                                            //   name: "In Progress",
                                            // },
                                            // {
                                            //   id: "Change Status",
                                            //   name: "Change Status",

                                            // },
                                            { id: "Medical", name: "Medical" },
                                            { id: "Reject", name: "Reject" },
                                          ]);
                                        } else {
                                          setStatuses([
                                            // {
                                            //   id: "In Progress",
                                            //   name: "In Progress",
                                            // },
                                            {
                                              id: "Entry Permit",
                                              name: "Entry Permit",
                                            },
                                            { id: "Reject", name: "Reject" },
                                          ]);
                                        }
                                      } else if (
                                        sorteddata[sorteddata.length - 1]
                                          .status == "Pending"
                                      ) {
                                        setStatuses([
                                          {
                                            id: "In Progress",
                                            name: "In Progress",
                                          },

                                          { id: "Reject", name: "Reject" },
                                        ]);
                                      } else if (
                                        sorteddata[sorteddata.length - 1]
                                          .status == "Entry Permit"
                                      ) {
                                        setStatuses([
                                          // {
                                          //   id: "Entry Permit",
                                          //   name: "Entry Permit",
                                          // },
                                          { id: "Medical", name: "Medical" },
                                          { id: "Reject", name: "Reject" },
                                        ]);
                                      } else if (
                                        sorteddata[sorteddata.length - 1]
                                          .status == "Change Status"
                                      ) {
                                        setStatuses([
                                          // {
                                          //   id: "Change Status",
                                          //   name: "Change Status",
                                          // },
                                          { id: "Medical", name: "Medical" },

                                          { id: "Reject", name: "Reject" },
                                        ]);
                                      } else if (
                                        sorteddata[sorteddata.length - 1]
                                          .status == "Medical"
                                      ) {
                                        setStatuses([
                                          // { id: "Medical", name: "Medical" },
                                          {
                                            id: "Emirates Id",
                                            name: "Emirates Id",
                                          },

                                          { id: "Reject", name: "Reject" },
                                        ]);
                                      } else if (
                                        sorteddata[sorteddata.length - 1]
                                          .status == "Emirates Id"
                                      ) {
                                        setStatuses([
                                          // {
                                          //   id: "Emirates Id",
                                          //   name: "Emirates Id",
                                          // },
                                          { id: "Stamping", name: "Stamping" },
                                          { id: "Reject", name: "Reject" },
                                        ]);
                                      } else if (
                                        sorteddata[sorteddata.length - 1]
                                          .status == "Stamping"
                                      ) {
                                        setStatuses([
                                          // { id: "Stamping", name: "Stamping" },

                                          { id: "Reject", name: "Reject" },
                                        ]);
                                      } else if (
                                        sorteddata[sorteddata.length - 1]
                                          .status == "Reject"
                                      ) {
                                        setStatuses([
                                          // { id: "Stamping", name: "Stamping" },

                                          {},
                                        ]);
                                      }
                                    }}
                                  >
                                    <Box
                                      component={"img"}
                                      src={
                                        sorteddata[sorteddata.length - 1]
                                          ?.status == "Medical"
                                          ? Images.blueCircle
                                          : sorteddata[sorteddata.length - 1]
                                            ?.status == "Stamping"
                                            ? Images.successIcon
                                            : sorteddata[sorteddata.length - 1]
                                              ?.status == "Emirates Id"
                                              ? Images.lightGreenCircle
                                              : sorteddata[sorteddata.length - 1]
                                                ?.status == "Entry permit"
                                                ? Images.pendingIcon
                                                : sorteddata[sorteddata.length - 1]
                                                  ?.status == "Change Status"
                                                  ? Images.pendingIcon
                                                  : Images.errorIcon
                                      }
                                      width={"13px"}
                                    ></Box>
                                    {sorteddata[sorteddata.length - 1]?.status}
                                  </Box>
                                </Cell>
                                <Cell
                                  style={{ textAlign: "left",cursor :item?.visa_type.toLowerCase() == "out" ? 'pointer' : ''  }}
                                  className="pdf-table"
                                  onClick={()=> {
                                    if(item?.visa_type.toLowerCase() == "out"){
                                      setSelectedVisa(item)
                                      setDateDialog(true)
                                    }
                                  }}
                                >
                                  {item?.change_status_date
                                    ? moment(item?.change_status_date).format(
                                      "MM-DD-YYYY"
                                    )
                                    : "-"}
                                </Cell>
                                <Cell
                                  style={{ textAlign: "left" }}
                                  className="pdf-table"
                                >
                                  <Box>
                                    <Box
                                      component={"img"}
                                      src={Images.detailIcon}
                                      onClick={() => {
                                        if (permissions?.processing_details) {

                                          navigate(
                                            `/view-candidate-detail/${item?.id}`
                                          )
                                        }

                                      }
                                      }
                                      width={"35px"}
                                    ></Box>
                                  </Box>
                                </Cell>
                              </Row>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </PDFExport>
                  {/* ========== Pagination ========== */}
                  <Pagination
                    currentPage={currentPage}
                    pageSize={pageLimit}
                    // onPageSizeChange={(size) => getVisaRequestList(1, size.target.value)}/
                    tableCount={customerQueue?.length}
                    totalCount={totalCount}
                  // onPageChange={(page) => getVisaRequestList(page, "")}
                  />
                </Fragment>
              )}

              {loader && <CircleLoading />}
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default VisaList;
