import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
    Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses, IconButton, CircularProgress, Chip, Grid, InputLabel,
    FormControl,
    Select,
    MenuItem,
    ListItemText,
    Tooltip,
    Checkbox,
    InputAdornment,
    Button,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Dialog,
    DialogActions,
} from '@mui/material';
import { AllocateIcon, CheckIcon, EyeIcon, FontFamily, Images, MessageIcon, PendingIcon, RequestBuyerIdIcon } from 'assets';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import Colors from 'assets/Style/Colors';
import { CircleLoading } from 'components/Loaders';
import { ErrorToaster, SuccessToaster } from 'components/Toaster';
import FinanceStatusDialog from 'components/Dialog/FinanceStatusDialog';
import AllocateStatusDialog from 'components/Dialog/AllocateStatusDialog';
import AllocateDialog from 'components/Dialog/AllocateDialog';
import CustomerServices from 'services/Customer';
import { makeStyles } from '@mui/styles';
import Pagination from 'components/Pagination';
import { CleanTypes, Debounce, encryptData, formatPermissionData, getFileSize, handleDownload, handleExportWithComponent } from 'utils';
import InputField from 'components/Input';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addPermission } from 'redux/slices/navigationDataSlice';
import SimpleDialog from 'components/Dialog/SimpleDialog';
import { PrimaryButton } from 'components/Buttons';
import SelectField from 'components/Select';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { PDFExport } from '@progress/kendo-react-pdf';
import moment from 'moment';
import CommissionServices from 'services/Commission';
import LabelCustomInput from 'components/Input/LabelCustomInput';
import { showErrorToast, showPromiseToast } from 'components/NewToaster';
import { adjustSectionValue } from '@mui/x-date-pickers/internals/hooks/useField/useField.utils';
import DatePicker from 'components/DatePicker';
import VisaServices from 'services/Visa';
import { CloudUpload } from '@mui/icons-material';
import instance from 'config/axios';
import routes from 'services/System/routes';
import { BoxTypes } from 'devextreme-react/cjs/box';
import { mt } from 'date-fns/locale';
import InvoiceServices from 'services/Invoicing';
import SystemServices from 'services/System';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
    border: 0,

}));

const Cell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 14,
        fontFamily: 'Public Sans',
        border: '1px solid #EEEEEE',
        padding: '15px',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        color: '#434343',
        paddingRight: '50px',
        background: 'transparent',
        fontWeight: 'bold'

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: 'Public Sans',

        textWrap: 'nowrap',
        padding: '5px !important',
        paddingLeft: '15px !important',
        '.MuiBox-root': {
            display: 'flex',
            gap: '6px',
            alignItems: 'center',
            justifyContent: 'center',
            '.MuiBox-root': {
                cursor: 'pointer'
            }
        },
        'svg': {
            width: 'auto',
            height: '24px',
        },
        '.MuiTypography-root': {
            textTransform: 'capitalize',
            fontFamily: FontFamily.NunitoRegular,
            textWrap: 'nowrap',
        },
        '.MuiButtonBase-root': {
            padding: '8px',
            width: '28px',
            height: '28px',
        }
    },
}));

const useStyles = makeStyles({
    loaderWrap: {
        display: 'flex',
        height: 100,
        '& svg': {
            width: '40px !important',
            height: '40px !important'
        }
    }
})

function MasterReport() {
    const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm();
    const {
        register: register2,
        handleSubmit: handleSubmit2,
        setValue: setValue2,
        getValues: getValues2,
        formState: { errors: errors2 },

    } = useForm();
    // *For Deposit Slip
    const [progress, setProgress] = useState(0);
    const [uploadedSize, setUploadedSize] = useState(0);
    const [slipDetail, setSlipDetail] = useState([]);
    const [slipLink, setSlipLink] = useState("");

    const navigate = useNavigate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const contentRef = useRef(null);
    const [statusDialog, setStatusDialog] = useState(false)
    const [paymentDialog, setPaymentDialog] = useState(false)
    const [status, setStatus] = useState(null)
    const [payment, setPayment] = useState(null)
    const [selectedVisa, setSelectedVisa] = useState()
    const [charges, setCharges] = useState()
    const [selectedItem, setSelectedItem] = useState(null);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const tableHead = [{ name: 'Client', key: 'created_at' }, { name: 'Request ID', key: 'name' }, { name: 'Request Date', key: 'commission_visa' }, { name: 'Employee ID', key: 'commission_monthly' }, { name: 'Name', key: 'commission_monthly' }, { name: 'Designation', key: 'commission_monthly' }, { name: 'Nationality', key: 'commission_monthly' }, { name: 'Passport No.', key: 'commission_monthly' }, { name: 'Basic Salary (AED)', key: 'commission_monthly' }, { name: 'Allowance (AED)', key: 'commission_monthly' }, { name: 'Total Salary (AED)', key: 'commission_monthly' }, { name: 'Apply Visa ', key: 'commission_monthly' }, { name: 'Status Date', key: 'commission_monthly' }, { name: 'Contact No', key: 'commission_monthly' }, { name: 'Visa Payment', key: 'commission_monthly' }, { name: 'Security Cheque', key: 'commission_monthly' }, { name: 'Documents', key: 'created_at' }, { name: 'Camp Location', key: 'commission_monthly' }, { name: 'Visa Fee As per Agreement', key: 'commission_monthly' }, { name: 'Visa Cost ', key: 'commission_monthly' }, { name: 'PRO Typing Fee', key: 'commission_monthly' }, { name: 'Mabde  ', key: 'commission_monthly' }, { name: 'Agent Fee ', key: 'commission_monthly' }, { name: 'Net Profit MABDE', key: 'commission_monthly' }, { name: 'Monthly Charges', key: 'commission_monthly' }, { name: 'Agent', key: 'commission_monthly' }, { name: 'Fahad', key: 'commission_monthly' }, { name: 'Sultan', key: 'commission_monthly' }]


    const allowFilesType = [

        'application/pdf',

    ];


    const [loader, setLoader] = useState(false);

    const [sort, setSort] = useState('asc')


    // *For Customer Queue
    const [visas, setVisas] = useState([]);



    // *For setPermissions
    const [totalCount, setTotalCount] = useState(0);
    const [pageLimit, setPageLimit] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);

    const [itemAmount, setItemAmount] = useState()

    // *For Filters
    const [filters, setFilters] = useState({});

    // *For Permissions
    const [permissions, setPermissions] = useState();

    const [loading, setLoading] = useState(false)

    // *For Customer Queue
    const [customerQueue, setCustomerQueue] = useState([]);

    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [selectedVisaType, setSelectedVisaType] = useState(null)
    const [documents, setDocuments] = useState([])
    const [docs, setDocs] = useState([])

    // *For Get Customer Queue

    const getCustomerQueue = async (page, limit, filter) => {
        // setLoader(true)
        try {
            const Page = page ? page : currentPage
            const Limit = limit ? limit : pageLimit
            const Filter = { ...filters, ...filter }
            setCurrentPage(Page)
            setPageLimit(Limit)
            setFilters(Filter)
            let params = {
                page: Page,
                limit: Limit,
            }
            params = { ...params, ...Filter }
            const { data } = await CustomerServices.getCustomerQueue(params)

            setCustomerQueue(data?.rows)

        } catch (error) {
            showErrorToast(error)
        } finally {
            // setLoader(false)
        }
    }
    // *For Upload Document
    const handleUploadDocument = async (e) => {
        try {
            e.preventDefault();
            const file = e.target.files[0];
            let arr = [
                {
                    name: file?.name,
                    file: "",
                    type: file?.type.split("/")[1],
                    size: getFileSize(file.size),
                    isUpload: false,
                },
            ];
            if (allowFilesType.includes(file.type)) {

                handleUpload(file, arr);
                const path = await handleUpload(file, arr);
                console.log('Uploaded file path:', path);
                setSlipLink(path)
                console.log(path, 'pathpathpath');
                return path
            } else {
                ErrorToaster(`Only ${CleanTypes(allowFilesType)} formats is supported`);
            }
        } catch (error) {
            ErrorToaster(error);
        }
    };



    const handleRadioChange = (item) => {
        setSelectedItem(item);
    };
    const handleUpload = async (file, docs) => {
        setProgress(0);
        try {
            const formData = new FormData();
            formData.append("document", file);
            console.log(file);
            const { data } = await instance.post(routes.uploadDocuments, formData, {
                onUploadProgress: (progressEvent) => {
                    const uploadedBytes = progressEvent.loaded;
                    const percentCompleted = Math.round(
                        (uploadedBytes * 100) / progressEvent.total
                    );

                    setProgress(percentCompleted);
                    console.log(getFileSize(uploadedBytes));
                    setUploadedSize(getFileSize(uploadedBytes));
                },
            });
            if (data) {
                docs[0].isUpload = true;
                docs[0].file = data?.data?.nations;
                setSlipDetail(docs);
                console.log(data, 'asddasasd');
                return data?.data?.path

            }
        } catch (error) {
            ErrorToaster(error);
        }
    };

    // *For Get Customer Queue
    const getVisaSales = async (page, limit, filter, id) => {
        // setLoader(true)
        try {
            const Page = page ? page : currentPage
            const Limit = limit ? limit : pageLimit
            const Filter = { ...filters, ...filter }
            setCurrentPage(Page)
            setPageLimit(Limit)
            setFilters(Filter)
            let params = {
                page: Page,
                limit: Limit,
                customer_id: id ? id : selectedCustomer?.id,

            }
            params = { ...params, ...Filter }

            const { data } = await InvoiceServices.getVisaSales(params)
            setVisas(data?.rows)
            setTotalCount(data?.count)

        } catch (error) {
            showErrorToast(error)
        } finally {
            // setLoader(false)
        }
    }









    const downloadExcel = () => {
        // Define headers and data separately
        const headers = tableHead.filter((item) => item !== "Status" && item !== "Actions");
        const data = [];
        // Extract values from objects and create an array for each row
        const rows = data.map((item, index) => [
            item?.account_code ?? '-',
            item?.name ?? '-',
            item?.unit ?? '-',
            item?.primary_account_id ? 'Sub Account' : 'Primary',
            item?.cat?.name ?? '-',
            item?.sub_cat?.name ?? '-'
        ]);

        // Create a workbook with a worksheet
        const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // Convert the workbook to an array buffer
        const buf = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
            mimeType:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Save the file using FileSaver.js
        saveAs(new Blob([buf]), "data.xlsx");
    };

    const getData = async (formData) => {
        setLoading(true)
        console.log(formData);
        try {
            let params = {
                charges_type: 'rate'

            }

            const { data } = await SystemServices.getRates(params);

            let details = data?.charges
            setCharges(details)



        } catch (error) {

        } finally {
            setLoading(false);
        }
    }


    // *For Handle Filter
    const handleFilter = () => {
        let data = {
            search: getValues('search')
        }
        Debounce(() => getVisaSales(1, '', data));
    }

    const handleSort = (key) => {
        let data = {
            sort_by: key,
            sort_order: sort
        }
        Debounce(() => getVisaSales(1, '', data));
    }




    useEffect(() => {

        getData()
        getVisaSales()
        getCustomerQueue()
    }, []);

    return (
        <Box sx={{ p: 3 }}>

            <Dialog

                open={open}
                onClose={handleClose}
                maxWidth={'md'}
                fullWidth={true}

                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
               
                <DialogContent >
                    <DialogContentText
                        id="scroll-dialog-description"

                        tabIndex={-1}
                    >


                    </DialogContentText>
                    <Grid container  >


                    {docs?.length > 0 && <Typography mb={2} mt={2} sx={{ color: '#03091A', fontWeight: 'bold' }} variant="h6">Documents :</Typography>}
                <Grid container spacing={2} mt={2}>
                    {docs
                        ?.sort((a, b) => (a.path ? 0 : 1) - (b.path ? 0 : 1)) // Sort documents with empty path last
                        .map((doc, index) => (
                            <>

                                <Grid
                                    item
                                    md={6}
                                    lg={6}
                                    sx={{ cursor: 'pointer' }}
                                    component={'div'}

                                // Use index2 instead of index to avoid duplicate keys
                                >
                                    {/* Conditionally render name only for the first doc */}
                                    {(
                                        <Box sx={{ fontSize: '15px', fontWeight: 'bold' }}>{doc?.name}</Box>
                                    )}
                                    <Box sx={{ display: 'flex', gap: '10px', mt: '15px', flexWrap: 'wrap' }} >
                                        {doc?.paths.length > 0 ? doc?.paths.map((item, index2) => {
                                            return (
                                                <>
                                                    <Box component={'div'} sx={{ width: '30%' }} 
                                                     onClick={() =>{
                                                        if(item?.split('_').pop().includes('doc') || item?.split('_').pop().includes('xls') ){
                    
                                                            handleDownload(item, item?.split('_').pop());
                                                          }
                                                          else{
                                                            
                                                            window.open(process.env.REACT_APP_IMAGE_BASE_URL+item, '_blank');
                                                          }
                                                    }}
                                                    // onClick={() => {
                                                    //     if (item) {
                                                    //         handleDownload(item, item.split('_').pop());
                                                    //     }
                                                    // }}
                                                    >
                                                        <Box key={index2}>
                                                            {item ? (
                                                                <Box component={'img'} src={Images.docIcon} width={'35px'} />
                                                            ) : (
                                                                <DoDisturbIcon sx={{ fontSize: '35px', color: 'gray' }} />
                                                            )}
                                                        </Box>
                                                        <Link rel="noopener noreferrer">
                                                            {item.split('_').pop()}
                                                        </Link>
                                                    </Box>
                                                </>
                                            );
                                        }) :
                                            <DoDisturbIcon sx={{ fontSize: '35px', color: 'gray' }} />}

                                    </Box>

                                </Grid>
                            </>
                        ))}


                </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ fontFamily: 'Public Sans' }} onClick={handleClose}>Cancel</Button>

                </DialogActions>
            </Dialog>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>Master Report</Typography>

                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <PrimaryButton
                        title="Download PDF"
                        type="button"
                        style={{ backgroundColor: Colors.bluishCyan }}
                        onClick={() => handleExportWithComponent(contentRef)}
                    />
                    <PrimaryButton
                        title={"Download Excel"}
                        onClick={() => downloadExcel()}
                    />

                </Box>

            </Box>

            {/* Filters */}
            <Box >
                <Grid container spacing={2}>
                    {/* <Grid item xs={6} >
                        <LabelCustomInput type={'text'} bgcolor={'#FAFAFA'} color={Colors.primary} border={'3px solid #FAFAFA'} StartLabel={'Search'} placeholder={'Search'} register={register("search")} />
                    </Grid> */}
                    <Grid item xs={3} >
                        <SelectField
                            size={'small'}
                            label={'Select Customer :'}

                            options={customerQueue}
                            selected={selectedCustomer}
                            onSelect={(value) => {
                                setSelectedCustomer(value)



                                getVisaSales(null, null, null, value?.id)

                            }}
                            error={errors?.customer?.message}
                            register={register("customer", {
                                required: 'Please select customer account.',
                            })}
                        />
                    </Grid>

                    {/* <Grid item xs={3} >
                        <LabelCustomInput type={'text'} bgcolor={'#FAFAFA'} color={Colors.primary} border={'3px solid #FAFAFA'} StartLabel={'By Customers'} placeholder={'Enter Name'}   register={register("payroll")} />
                    </Grid>
                    <Grid item xs={3} >
                        <LabelCustomInput bgcolor={'#FAFAFA'} color={Colors.primary} border={'3px solid #FAFAFA'} StartLabel={'By Commission'} placeholder={'Enter Name'}   register={register("payroll")} />
                    </Grid>
                    <Grid item xs={3} >
                        <LabelCustomInput bgcolor={'#FAFAFA'} color={Colors.primary} border={'2px solid #FAFAFA'} StartLabel={'By Date'} placeholder={'Enter Name'}   register={register("payroll")} />
                    </Grid> */}
                    {/* <Grid item xs={6} display={'flex'} justifyContent={'flex-end'} gap={2} >
                        <PrimaryButton
                            bgcolor={Colors.white}
                            textcolor={Colors.primary}
                            // border={`1px solid ${Colors.primary}`}
                            title="Clear"
                            onClick={() => { getVisaSales(); setValue('search', '') }}
                            loading={loading}
                        />
                        <PrimaryButton
                            bgcolor={Colors.buttonBg}
                            title="Search"
                            onClick={() => handleFilter()}
                            loading={loading}
                        />
                    </Grid> */}
                </Grid>

                <Grid item md={11}>
                    {visas.length > 0 && <Box>

                        <Grid container mb={2} >

                        </Grid>



                        {(
                            visas && (
                                <Fragment>
                                    <PDFExport ref={contentRef} landscape={true} paperSize="A4" margin={5} fileName='Visa Sales Revenue'
                                        pageTemplate={({ pageNumber, totalPages }) => (
                                            <>
                                                {/* Header */}
                                                <Box style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    height: '50px',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    margin: '25px',


                                                }}>


                                                    <Box>
                                                        Visa Sales Revenue
                                                    </Box>
                                                    <Box>
                                                        <img src={Images.logoDark} style={{ width: '200px' }} alt="Header" />
                                                    </Box>

                                                </Box>

                                                {/* Footer */}
                                                {/* <Box style={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    height: '50px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <img src={Images.invoiceHeader} style={{ width: '100%' }} alt="Footer" />
                                                </Box> */}
                                            </>
                                        )}>
                                        {/* Main content */}
                                        <TableContainer
                                            component={Paper}
                                            sx={{
                                                maxHeight: '100%',
                                                mt: 5,
                                                backgroundColor: 'transparent',
                                                boxShadow: 'none !important',
                                                borderRadius: '0px !important',
                                                paddingTop: '60px',  // Adjust for header height
                                                paddingBottom: '60px' // Adjust for footer height
                                            }}
                                        >
                                            <Table stickyHeader sx={{ minWidth: 500 }}>
                                                <TableHead>
                                                    <Row>
                                                        {tableHead.map((cell, index) => (
                                                            <Cell style={{
                                                                textAlign: cell?.name === 'Select' ? 'center' : 'left',
                                                                paddingRight: cell?.name === 'Select' ? '15px' : '20px'
                                                            }}
                                                                className="pdf-table" key={index}>
                                                                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                                    {cell?.name}
                                                                    {cell?.name === 'Date' && (
                                                                        <>
                                                                            &nbsp;
                                                                            <span style={{ height: '20px', cursor: 'pointer' }}>
                                                                                <Box component={'img'}
                                                                                    onClick={() => { setSort(sort === 'asc' ? 'desc' : 'asc'); handleSort(cell?.key) }}
                                                                                    src={Images.sortIcon}
                                                                                    width={'18px'}>
                                                                                </Box>
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </Box>
                                                            </Cell>
                                                        ))}
                                                    </Row>
                                                </TableHead>
                                                <TableBody>
                                                    {visas.map((item, index) => {
                                                        let foundStatus = item?.statuses.find((item, index) => item?.status == 'Entry permit' || item?.status == 'Change Status')
                                                        let statusDate = foundStatus?.date ? foundStatus?.date : foundStatus?.created_at
                                                        let VisaCost = 0
                                                        if (item?.visa_tenure === '1 year' && item?.visa_type === 'In') {
                                                            VisaCost = parseFloat(charges?.one_year_inside) + parseFloat(charges?.one_year_renewal);
                                                        } else if (charges?.visa_tenure === '1 year' && charges?.visa_type === 'Out') {
                                                            VisaCost = parseFloat(charges?.one_year_outside) + parseFloat(charges?.one_year_renewal);
                                                        } else if (charges?.visa_tenure === '2 year' && charges?.visa_type === 'In') {
                                                            VisaCost = parseFloat(charges?.two_year_inside) + parseFloat(charges?.two_year_renewal);
                                                        } else if (charges?.visa_tenure === '2 year' && charges?.visa_type === 'Out') {
                                                            VisaCost = parseFloat(charges?.two_year_outside) + parseFloat(charges?.two_year_renewal);
                                                        } else {
                                                            // Handle other cases or set a default value if needed
                                                            VisaCost = 0;
                                                        }
                                                        return (
                                                            <Row key={index} sx={{ border: '1px solid #EEEEEE !important' }}>
                                                                <Cell style={{ textAlign: 'center', paddingLeft: '0px !important' }} className="pdf-table">
                                                                    {item?.customer?.name}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.visa_id}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {moment(item?.visa_request?.request_date).format('MM-DD-YYYY')}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.employee_id}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.name}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.visa_designation}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.nationality?.name}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.passport_number}
                                                                </Cell>

                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.salary_basic}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.salary_allowance}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.salary_total}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.visa_type}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {statusDate ? moment(statusDate).format('MM-DD-YYYY') : '-'}
                                                                </Cell>

                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.phone}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.visa_charges}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">

                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    <Box component={'img'} sx={{cursor:'pointer'}} onClick={()=> { 
                                                                        const processedDocuments = item?.documents?.map((doc) => ({
                                                                            ...doc,
                                                                            paths: doc.path ? doc.path.split(",") : [], // Split the path by commas
                                                                        })) || [];
                                                                        console.log(processedDocuments);
                                                            
                                                                        setDocs(processedDocuments);
                                                            
                                                                         setOpen(true)}} src={Images.docIcon} width={'35px'} /> Documents
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.camp_location}
                                                                </Cell>


                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.visa_charges}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {VisaCost}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {charges?.typing_fee}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    0
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    0
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    0
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    {item?.customer?.monthly_visa_service}
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    0
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    0
                                                                </Cell>
                                                                <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                    0
                                                                </Cell>
                                                            </Row>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </PDFExport>

                                    {/* ========== Pagination ========== */}
                                    <Pagination
                                        currentPage={currentPage}
                                        pageSize={pageLimit}
                                        onPageSizeChange={(size) => getVisaSales(1, size.target.value)}
                                        tableCount={visas?.length}
                                        totalCount={totalCount}
                                        onPageChange={(page) => getVisaSales(page, "")}
                                    />

                                </Fragment>
                            )
                        )}


                        {loader && <CircleLoading />}


                    </Box>}





                </Grid>
            </Box>

        </Box>
    );
}

export default MasterReport;