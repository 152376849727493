const routes = {
	getAgents: "/agents",
	CreateAgent:'/agents/add',
	getAgentDetail:'/agents/details',
	UpdateAgent:'agents/update'
	
	
};

export default routes;
